.ant-menu-item {
  background-color: transparent !important;
  color: #9b9696 !important;
}

.ant-menu-item-selected {
  background-color: #00ffff !important;
  color: rgba(0, 0, 0, 0.88) !important;
}

.ant-menu-item-selected::after {
  border-bottom-color: rgba(0, 0, 0, 0.88) !important;
}
